<template>
   <!-- EVENT SCHEDULES -->
    <div id="event_schedules">
       
  <div id="event_schedules">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h2>Event Schedules</h2>
        <hr class="horizontal_line" />
      </div>
      <a :href="pdfLink" download="download" class="btn btn-info"
        >Download Program Outline</a
      >
      
    </div>

    
    <div class="row justify-content-center card">
      <div class="col-md-12">
        
         <table class="table table-striped">
              <thead class="thead-dark">
                <tr class="py-2">
                  <th scope="col">Time (EAT)</th>
                  <th scope="col">Afrochampions Contributors</th>
                  <th scope="col">Session</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><i class="far fa-clock"></i> <span>13:00 EAT</span></td>
                  <td>
                    Edem Adzogenu<br />
                    Michael Kottoh<br />
                    Mahama Ouedraogo<br />
                    Josefa Sacko<br />
                    Albert Muchanga <br>
                    John Nkengasong <br>
                    Nicaise Ndembi
                  </td>
                  <td>Launch of 4D and Update on the Trillion Dollar Fund.</td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>13:00 EAT</span></td>
                  <td>
                    Catherine Kyobutungi<br />
                    Yvonne Mburu
                  </td>
                  <td>
                    Sankoree Debate Series:<br />
                    African Africa must walk with Basic Drugs before it can run
                    with Vaccines<br />
                    Opening Arguments – 7 to 10 minutes each.<br />
                  </td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>14:00 EAT</span></td>
                  <td>
                    Ndumiso Luthuli<br />
                    Gyekye Tandoh
                  </td>
                  <td>
                    Sankoree Debate Series:<br />
                    African Countries Should Just Nationalise their Natural
                    Resources.<br />
                    Opening Arguments – 10 minutes each.<br />
                    Counter-Arguments – 5 minutes each.
                  </td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>15:00 EAT</span></td>
                  <td>
                    Bineta Diop<br />
                    Okonjo Iweala <br />
                    Ellen Johnson-Sirleaf
                  </td>
                  <td>
                    Sankoree PowerChat Series: <br />
                    Is the World Cheating Africa?
                  </td>
                </tr>
                <tr class="bg-danger text-white">
                  <td colspan="4">
                    <b
                      >Part I: SKILLS, TALENT & MOBILITY AS INTEGRATION
                      DRIVERS</b
                    >
                  </td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>15:30 EAT</span></td>
                  <td>
                    Stan Nkwain,<br />
                    Ms Manureen Achieng<br />
                    Emmanuel Bensah <br>
                    Zawadi Mudibo<br />
                    Chazha Ludo Macheng <br />
                    Jeminama Idinoba<br />
                    Zodwa Mabuzza <br />
                    Isabella Rouche <br />
                    Rolake Roiji <br />
                    Sam Daniel <br />
                    Raymond Gilpin
                  </td>
                  <td>
                    Intro of AYMP concept by Moderator <br />
                    Opening Remarks <br />
                    Keynote <br />
                    Youth Representative <br />
                    Panel Discussion
                  </td>
                </tr>
                <tr class="bg-danger text-white">
                  <td colspan="4">
                    <b>Part II: SMES & CONTINENTAL VALUE CHAINS</b>
                  </td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>15:30 EAT</span></td>
                  <td>
                    H.E Wamkele Mene,<br />
                    Zawaadi Mudibo<br />
                    SME Innovators<br />
                    Child Library <br />
                    Martyn Davies<br />
                    Myrian Sidibe <br />
                    Sidi Ould Tah <br>
                    Kweku Bedu Addo <br />
               
                  </td>
                  <td>
                    The Caravan Prize & Initiative Preview <br />
                    Intro of Caravan concept by Moderator <br />
                    AfCFTA Secretary- General's remarks<br />
                    SME's Innovators & Freelancers Sportlight <br />
                    Sportlightees share 5-minute high-level pitch of their idea
                    or project. <br />
                    Corporate Leaders & Mentors give feedback and guidance
                    <br />
                    Conference wrap up
                  </td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>17:30 EAT</span></td>
                  <td>
                    Martin Mbaya <br />
                    Taurai Chinyamakovbu <br />
                  </td>
                  <td>
                    Sankoree Debate Series:<br />
                    In a time of Blockchain,<br />
                    Africa has no need of Single Currencies.
                  </td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i> <span>18:30 EAT</span></td>
                  <td>
                    Samba Bathily <br />
                    Kunle Adeyemi <br />
                    Olusegum Obasanjo <br />
                    Jeannia Tsiory <br />
                    Bob Odhiambo <br>
                    Vincent Kolo <br>
                    Sinenkosi <br>
                    Bertha Munthali <br>
                  </td>
                  <td>
                    Nefertiti Recap: The Mindelo Chronicles <br />
                    <br />
                    CLOSING REMARKS <br />
                    <br />
                    Closing Artistic Performance:<br />
                    Nefertiti: Grooving with the Queenmother of Continents<br />
                    (working title)
                  </td>
                </tr>
                <tr class="bg-danger text-white">
                  <td colspan="4">
                    <h1 class="vote text-center">
                      Vote of Thanks and Closing Video
                    </h1>
                  </td>
                </tr>
              </tbody>
            </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import pdf from 'vue-pdf'
export default {
  components:{
    pdf
  },
  data() {
    return {
      pdfLink: require("../../../public/pdf/Boma Program Outline.pdf"),
    }
  },
}

</script>

<style scode>
.vote {
  text-transform: uppercase;
}
.info {
  font-size: 1.2rem;
  color: #fff;
  background-color: #e91e63;
  border: none;
  outline: none;
  padding: 0.5em 3em;
}
.nav-item {
  background-color: #e91e63;
  color: #fff;
}
#event_schedules {
  padding: 7em 12%;
  background-color: #fff;
  width: 100%;
}
#event_schedules .card {
  margin-top: 3em;
  box-shadow: 1px 10px 20px rgb(0 0 0 / 20%);
  padding: 3em 1em;
}
#event_schedules h2 {
  color: #333333;
  font-size: 3em;
  text-align: center;
  font-weight: bolder;
}
.horizontal_line {
  width: 100px;
  height: 2px;
  background-color: #e91e63;
}
td,
tr {
  padding: 1em;
}
</style>