<template>
  <main>
    <div class="main">
      <nav class="container-fluid" id="my_nav">
        <input id="nav-toggle" type="checkbox" />

        <div class="logo">
          <a href="/">
            <div class="logo_logo">
              <img
                src="../../assets/new/AfCFTA Logo.png"
                height="60px"
                class="img-responsive mt-3"
                alt=""
              />
            </div>
            <div class="logo_text">
              <p class="big"><strong>AfCFTA</strong> BLOG</p>
              <p class="small">AN INITIATIVE OF THE AfCFTA</p>
              <p class="small">SECRETARIAT</p>
            </div>
          </a>
        </div>

        <ul class="links">
          <li :class="[$route.name === 'Home' ? 'active' : '']">
            <router-link to="/">Home</router-link>
          </li>
          <li :class="[$route.name === 'boma' ? 'active' : '']">
            <router-link to="/BOMA">Boma</router-link>
          </li>
        </ul>

        <label for="nav-toggle" class="icon-burger">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </label>
      </nav>
    </div>
    <section class="">
      <!-- HERO SECTION -->
      <div class="hero" id="hero_section">
        <div class="row">
          <div class="col-lg text-center main-container">
            <h2>The Boma of Africa</h2>
            <h3>on Africa Integration Day</h3>
            <a class="join" target="_blank" href="https://us02web.zoom.us/webinar/register/WN_fdqtonjATOmFkgi-gZ6sPA">REGISTER FOR EVENT</a>
          </div>
        </div>
      </div>
      <!-- END HERO SECTION -->
      <!-- ABOUT EVENT -->
      <div id="about_event" class="contaner-fluid">
        <div class="row">
          <div class="col-md-12 text-center">
            <h2>About <span style="color:#e91e63">The Boma</span> </h2>
            <hr class="horizontal_line" />
            <p class="">
              By decree of Africa's Heads of State, Africa Integration Day falls
              on the 7th of July every year but the activities extend throughout
              the week under the high patronage of the African Union Commission.
              AfroChampions, as a Strategic Partner of the African Union,
              co-hosts the Boma of Africa event with the African Union and the
              AfCFTA Secretariat, as well as other valuable partners, every year
              during Africa Integration Week. In 2021, the UNDP, IOM and BADEA
              have joined the roster of co-hosts to mobilise audiences across
              Africa to focus on the urgent and pressing subjects shaping the
              destiny of our beloved continent. Don't sit on the fence. Join us
              and enliven the dialogues with your unique perspectives.
            </p>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div class="location">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#fff"
              width="20"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"
              />
            </svg>
            Online
          </div>
        </div>

        <div class="row justify-content-center time_component">
          <div class="col-md-3 mb-2 mt-2 days" v-for="time in times" :key="time.id">
            <div class="time">
              <div class="row">
                <div class="col-md-12">
                  <span>{{ time.time }}</span>
                  {{ time.text }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="d-flex text-center align-items-center justify-content-center"
        >
          <a class="join" target="_blank" href="https://us02web.zoom.us/webinar/register/WN_fdqtonjATOmFkgi-gZ6sPA">REGISTER FOR EVENT</a>
        </div>
      </div>
      <!-- END OF ABOUT EVENT -->
      <!-- SPEAKERS -->
      <div id="speakers">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-12">
            <h2>Featured Speakers</h2>
            <hr class="horizontal_line" />
          </div>
          <div class="row justify-content-center">
            <div class="col-md-5 col-lg-4 col-sm-10">
              <div class="card">
                <div class="card_image">
                  <img
                    class="img-fluid"
                    src="../../assets/Boma/Ibukun-Awosika.jpg"
                    alt=""
                  />
                </div>
                <div class="card_text">
                  <h3>Ibukun Awosika</h3>
                  <p>Business Leader & Policy Influencer</p>
                </div>
              </div>
            </div>
            <!-- <div class="col-md-5 col-lg-4 col-sm-10">
              <div class="card">
                <div class="card_image">
                  <img
                    class="img-fluid"
                    src="../../assets/Boma/Julius-Malema.jpg"
                    alt=""
                  />
                </div>
                <div class="card_text">
                  <h3>Julius Malema</h3>
                  <p>CIC & President of Economic Freedom Fighters</p>
                </div>
              </div>
            </div> -->
            <div class="col-md-5 col-lg-4 col-sm-10">
              <div class="card">
                <div class="card_image">
                  <img
                    class="img-fluid"
                    src="../../assets/Boma/woman.jpg"
                    alt=""
                  />
                </div>
                <div class="card_text">
                  <h3>Rosa Whitaker</h3>
                  <p>CEO & President of Whitaker Group</p>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-4 col-sm-10">
              <div class="card">
                <div class="card_image">
                  <img
                    class="new_image"
                    src="../../assets/Boma/mene.jpeg"
                    alt=""
                  />
                </div>
                <div class="card_text">
                  <h3>Wamkele Mene</h3>
                  <p>Secretary General, AfCFTA Secretariat</p>
                </div>
              </div>
            </div>
            <div class="col-md-5 col-lg-4 col-sm-10">
                <div class="card">
                  <div class="card_image">
                    <img 
                      class="new_image"
                      src="../../assets/Boma/ahunna.jpeg" alt="">
                  </div>
                  <div class="card_text">
                    <h3>Ahunna Eziakonwa</h3>
                    <p>UNDP Assistant Administrator</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-lg-4 col-sm-10">
                <div class="card">
                  <div class="card_image">
                    <img 
                      class="new_image"
                      src="../../assets/Boma/albert.jpeg" alt="">
                  </div>
                  <div class="card_text">
                    <h3>Albert Muchanga</h3> 
                    <p>Commissioner, AUC</p>
                  </div>
                </div>
              </div>
               <div class="col-md-5 col-lg-4 col-sm-10">
                <div class="card">
                  <div class="card_image">
                    <img 
                      class="new_image"
                      src="../../assets/Boma/josefa.jpeg" alt="">
                  </div>
                  <div class="card_text">
                    <h3>Josefa Sacko</h3> 
                    <p>Commissioner, AUC</p>
                  </div>
                </div>
              </div>
               <div class="col-md-5 col-lg-4 col-sm-10">
                <div class="card">
                  <div class="card_image">
                    <img 
                      class="new_image"
                      src="../../assets/Boma/download (1).jpeg" alt="">
                  </div>
                  <div class="card_text">
                    <h3>Paulo Gomes</h3> 
                    <p>Chairman, Orango</p>
                  </div>
                </div>
              </div>
              <div class="col-md-5 col-lg-4 col-sm-10">
                <div class="card">
                  <div class="card_image">
                    <img 
                      class="new_image"
                      src="../../assets/Boma/download.jpeg" alt="">
                  </div>
                  <div class="card_text">
                    <h3> Add Bineta Diop</h3> 
                    <p>AU Special Envoy</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 py-5 ">
            <h3 class="text-center">More confirming daily...</h3>
          </div>
        </div>
      </div>
      <!-- END OF SPEAKERS -->
      <boma-shedules />
    </section>
    <Footer />
  </main>
</template>

<script>
import VueSessionStorage from "vue-sessionstorage";
import Storage from "../../helpers/storage";
import url from "../../helpers/url";
import service from "../../helpers/service";
import BomaShedules from "./BomaSchedule.vue";
import Footer from '../New/Footer.vue'
export default {
  components: { BomaShedules, Footer },
  data() {
    return {
      startTime: "July 8, 2021 13:00:00",
      endTime: "July 8, 2021 19:00:00",
      times: [
        { id: 0, text: "Days", time: 1 },
        { id: 1, text: "Hours", time: 1 },
        { id: 2, text: "Minutes", time: 1 },
        { id: 3, text: "Seconds", time: 1 },
      ],
      progress: 100,
      timeinterval: undefined,
    };
  },
  methods: {
    url() {
      return url();
    },
    service() {
      return service();
    },
    logout() {
      Storage.removeAll();
    },
    getUser() {
      this.user = Storage.get("token");
    },
    updateTimer: function () {
      if (
        this.times[3].time > 0 ||
        this.times[2].time > 0 ||
        this.times[1].time > 0 ||
        this.times[0].time > 0
      ) {
        this.getTimeRemaining();
        this.updateProgressBar();
      } else {
        clearTimeout(this.timeinterval);
        // this.times[3].time = this.times[2].time = this.times[1].time = this.times[0].time = 0;
        this.progress = 0;
      }
    },
    getTimeRemaining: function () {
      let t = Date.parse(new Date(this.endTime)) - Date.parse(new Date());
      if (t >= 0) {
        this.times[3].time = Math.floor((t / 1000) % 60); //seconds
        this.times[2].time = Math.floor((t / 1000 / 60) % 60); //minutes
        this.times[1].time = Math.floor((t / (1000 * 60 * 60)) % 24); //hours
        this.times[0].time = Math.floor(t / (1000 * 60 * 60 * 24)); //days
      } else {
        this.times[3].time =
          this.times[2].time =
          this.times[1].time =
          this.times[0].time =
            0;
        this.progress = 0;
      }
    },
    updateProgressBar: function () {
      let startTime = Date.parse(new Date(this.startTime));
      let currentTime = Date.parse(new Date());
      let endTime = Date.parse(new Date(this.endTime));
      let interval = parseFloat(
        ((currentTime - startTime) / (endTime - startTime)) * 100
      ).toFixed(2);
      this.progress = 100 - interval;
    },
  },
  created() {
    this.getUser();
    this.updateTimer();
    this.timeinterval = setInterval(this.updateTimer, 1000);
  },
  computed: {
    dateTime() {
      // return new Date(`0`)
    },
  },
};
</script>

<style  scoped>
.new_image{
  background-position: center;
  background-size: cover;
  width:100%;
}
.info {
  font-size: 1.2rem;
  color: #e91e63;
}
/* BOMA OF AFRICA */
#hero_section {
  background-image: url("../../assets/rsz_bg-min.jpg");
  background-origin: center;
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 2000px rgba(209, 155, 8, 0.6);
}
#hero_section h2 {
  color: #fff;
  font-weight: bolder;
  font-size: 5em;
}
#hero_section h3 {
  color: #fff;
  font-weight: bold;
  font-size: 3em;
  text-align: center;
  padding-bottom: 20px;
}
.join {
  color: #fff;
  background-color: #e91e63;
  padding: 15px 35px;
  font-size: 20px;
  text-decoration: none;
  border: none;
  font-weight: bold;
  outline: none;
  border-radius: 10px;
}
.join:hover {
  border: none;
  outline: none;
  text-decoration: none;
}
@media screen and (min-width: 400px) and (max-width: 600px) {
  #hero_section {
    padding-right: 5%;
    padding-left: 5%;
  }
  #hero_section h2 {
    font-size: 4.5em;
  }
  #hero_section h3 {
    font-size: 2.5em;
  }
}
@media screen and (max-width: 400px) {
  #hero_section {
    padding-right: 5%;
    padding-left: 5%;
    padding-bottom: 7em;
    padding-top: 4em;
    height: 100vh;
  }
  #hero_section h2 {
    font-size: 3em !important;
    font-weight: bold;
  }
  #hero_section h3 {
    font-size: 2em !important;
    font-weight: normal;
  }
}
/* END OF BOMA OF AFRICA */
/* START OF ABOUT THE BOMA */
#about_event {
  background-color: #ffff;
  width: 100%;
  padding: 5em 12%;
  min-height: 500px !important;
}

#about_event .location {
  background-color: #333333;
  font-weight: 24px;
  color: #fff;
  text-align: center;
  padding: 12px 38px;
  border-radius: 5px;
  margin-top: 1em;
  opacity: 0.99;
  box-shadow: 1px 10px 20px rgb(0 0 0 / 20%);
}
.time_component {
  margin: 4em 10%;
}
.time {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 120px;
  height: 110px;
  padding: 20px;
  box-shadow: 1px 10px 20px rgb(0 0 0 / 20%);
  border-radius: 4px;
  background: #e91e63;
  text-align: center;
  font-weight: 400;
  color: #fff;
  font-size: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  line-height: 22px;
}
.time span {
  font-weight: bolder;
  font-size: 1.7em;
  display: block;
  padding-bottom: 10px;
}
/* END OF ABOUT THE BOMA */
/* START OF SPEAKERS SECTION */
#speakers {
  padding: 7em 12%;
  background-color: #f2f2f2;
  width: 100%;
}
#speakers h2,
#about_event h2,
#event_schedules h2 {
  color: #333333;
  font-size: 3em;
  text-align: center;
  font-weight: bolder;
}
#speakers h3 {
  color: #333333;
  font-weight: bold;
  line-height: 1.5em;
}
#speakers .card_text {
  padding: 1.5em 1em;
  text-align: center;
}
#speakers .card {
  margin-top: 3em;
  box-shadow: 1px 10px 20px rgb(0 0 0 / 20%);
}
.horizontal_line {
  width: 100px;
  height: 2px;
  background-color: #e91e63;
}
/* END OF SPEAKERS SECTION */

.main {
  background: #fff;
  width: 100%;
  padding-top: 110px;
  position: fixed;
  z-index: 10;
}
nav {
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  padding-right: 12%;
  padding-left: 12%;
}
.logo_logo {
  margin-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 10px;
}
.logo_text {
  border-left: 2px solid rgb(63, 62, 62);
  padding-left: 5px;
}
.logo_text P {
  padding: 0;
  margin: 0;
  line-height: 1.2em;
}
.logo_text small {
  padding: 0;
  margin: 0;
  line-height: 0px;
  font-size: 0.5em;
}
#res {
  margin-right: 40px;
}

.iconify {
  margin-right: 20px;
}

nav .logo a {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

nav .links {
  float: right;
  padding: 0;
  margin: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

nav .links li {
  list-style: none;
  position: relative;
  padding-top: 25px;
  /* background-color: #DC3545; */
}
nav ul li:hover::before,
.active::before {
  content: " ";
  background-color: #e91e63;
  top: 0;
  position: absolute;
  margin-left: 5px;
  width: 95%;
}

nav ul li:hover::after,
.active::after {
  content: " ";
  background-color: #e91e63;
  bottom: 0;
  position: absolute;
  margin-left: 5px;
  width: 95%;
  margin-left: 5px;

  height: 5px;
}

nav .links a {
  display: block;
  padding: 1em;
  font-size: 16px;
  /* font-weight: bold; */
  color: #000;
  text-decoration: none;
}

#nav-toggle {
  position: absolute;
  top: -100px;
}

nav .icon-burger {
  display: none;
  position: absolute;
  right: 0px;
  top: 40%;
  transform: translate(-58%);
}
/* .nav-icons a{
  color:#DC3545;
} */
.nav-icons {
  padding-top: 25px;
}
.nav-icons a {
  font-weight: bolder;
}
nav .icon-burger .line {
  width: 38px;
  height: 5px;
  background-color: #000;
  margin: 5px;
  border-radius: 3px;
  transition: all 0.3s ease-in-out;
}

.iconify {
  font-size: 20px;
  line-height: 1em;
}

@media screen and (max-width: 1100px) {
  nav {
    padding-right: 5%;
    padding-left: 5%;
  }
}

@media screen and (max-width: 900px) {
  nav .logo {
    width: auto;
    margin-left: 0;
    justify-content: center;
  }

  nav .links a {
    font-size: 20px;
    color: #fff;
  }

  #res {
    margin-right: 0;
  }

  nav .links {
    float: none;
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 100px;
    bottom: 100%;
    background: rgba(0, 0, 0, 0.9);
    width: auto;
    height: auto;
    justify-content: space-evenly;
    flex-direction: column;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }

  nav .icon-burger {
    display: block;
  }

  nav :checked ~ .links {
    bottom: 0;
  }

  nav :checked ~ .icon-burger .line:nth-child(1) {
    transform: translateY(10px) rotate(225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(3) {
    transform: translateY(-10px) rotate(-225deg);
  }

  nav :checked ~ .icon-burger .line:nth-child(2) {
    opacity: 0;
  }

  .iconify {
    font-size: 25px;
    color: white;
    line-height: 1em;
  }
}
@media screen and (max-width: 400px) {
  .logo_text p {
    font-size: 0.8em;
  }
  .logo_text .small {
    font-size: 0.6em !important;
  }
}
</style>